import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage3Avaleht from "./pages/Webpage3Avaleht";
import Webpage16Isikuandmete_t____tlemine_kandideerimisel from "./pages/Webpage16Isikuandmete_t____tlemine_kandideerimisel";
import Webpage17T____tamine_IT_Projektis from "./pages/Webpage17T____tamine_IT_Projektis";
import Webpage18Full_stack_arendaja_t____kuulutus___IT_Projekt from "./pages/Webpage18Full_stack_arendaja_t____kuulutus___IT_Projekt";
import Webpage19Backend_arendaja_t____kuulutus___IT_Projekt from "./pages/Webpage19Backend_arendaja_t____kuulutus___IT_Projekt";
import Webpage21Home from "./pages/Webpage21Home";
import Webpage22Teenused from "./pages/Webpage22Teenused";

var baseURL = 'https://web.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2IiwianRpIjoiNDQxZTczNjRkNDM5NmZmNGUwY2ZlYWRkZWE0N2U0MDI4ODk5NmVlM2IxZmY0ODQyYzIyNWQxMzY2MmY1Njg3ZmZmOTBkMzE5NTNlOWJlYjIiLCJpYXQiOjE3Mzc4MDY3OTguODQxNTY4LCJuYmYiOjE3Mzc4MDY3OTguODQxNTczLCJleHAiOjE3Mzc4OTMxOTguODM4NCwic3ViIjoiOCIsInNjb3BlcyI6W119.ttJZbext-HLsngtTTzsznLNzt16RFwA4Q-G6GXDgurCVzOn-n8EPq-BJ0CGgj2eD6hbrD8QKgUWlKyRKtsBEiCWFwC_M0_cnNf8HPR2GbzPqqkjVITxWsND-wK0Wtp76B5AuTXa-Q2wkGS582R4WOHVr78i9y10BjqYahcqBQDCGRvGJUT0Gz1uNVvyMLRiwvomYuQPRW1qpULUNWdSEC58mGWdcGzmVjNZ3M4ACNl6U00Dx6BJycvBqzYV99y-gs8eNfV624TjMFGgOurq9jZMqLxnZ5uhDkqfwXq2VKH0Nw-ZB37lfR44SldHkXrK-YoYeMHBAQ-v5xXmcIVMpRttb2i5dhTW3h6umD1MJb3ksIyktuw3mqQsmZ6OzIEHx66sVgp-p14VsBpnmTFBcxFaowOO_w9MBNmiKAct8acRDu-96-qijCScqbgPi9Gm-lED98Ut87Arp25TF-9zoMf2JbR2l9sjPQKBg4t9R-v_-fZssyL_lHuhXODv_0_R8fqhHp7u00TjBpKT9gZDWhAQ08pPZEK73tjVEvQDFDWGr3jRGRzB1TNz0xpDUcyjonH3h7ZINcwzPla6y3lgwDYwJu8QizMoi-5witjED1pOxrNN3sbPbsmGTvvZM5pixo9ABwAYF7Y7NVk8FqXHfTBql0e7alHVJ4tDEgEjmJqA',
    'Access-api-key': "eyJpdiI6IkdzanA0SzFxblRxc0tIdUxiZ3VvQVE9PSIsInZhbHVlIjoiQjk0dnVWUUlMdDdTVlJQYWZQR2VEZz09IiwibWFjIjoiYmM5MDEzODRjYTAxMDc0Y2EyMjU1MjBlMGM4NzNkZDBlNjc4ZWY3ZmUzYzliY2NiNzg4N2JmMjc4OGRjZWI5YSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6Ikd4WitVZHVHcXFlOGJSUEJENkZSbkE9PSIsInZhbHVlIjoiakVVb2ppdTc5akMvNHNvL2tWOTVaUT09IiwibWFjIjoiZDc4MjNhNTJjNGY3MGNlZTc4YTVhMDg1NTExYzM4NGZiMTQxYjk2OTgwMDIyYjFkZDQ2NjI4YzBlNDEwNGFjMyIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-MXH2B4"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage3Avaleht />} exact />;
                                                                    <Route path="/et/isikuandmed" element={<Webpage16Isikuandmete_t____tlemine_kandideerimisel />} exact />;
                                                                    <Route path="/et/tootamine-it-projektis" element={<Webpage17T____tamine_IT_Projektis />} exact />;
                                                                    <Route path="/et/full-stack" element={<Webpage18Full_stack_arendaja_t____kuulutus___IT_Projekt />} exact />;
                                                                    <Route path="/et/backend" element={<Webpage19Backend_arendaja_t____kuulutus___IT_Projekt />} exact />;
                                                                    <Route path="/en/" element={<Webpage21Home />} exact />;
                                                                    <Route path="/et/teenused" element={<Webpage22Teenused />} exact />;
                                                                
                                            <Route path="itprojekt.ee/et/isikuandmed" element={<Redirect to="itprojekt.ee/et/tootamine-it-projektis/isikuandmed" />} exact />;
                                                                <Route path="*" element={<Webpage3Avaleht />} exact />;
                                        </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}